var app;
(function (app) {
    var media;
    (function (media) {
        'use strict';
        var MediaController = /** @class */ (function () {
            /* @ngInject */
            MediaController.$inject = ["$scope", "$element", "$document", "$timeout", "$q", "dataservice", "$state", "$location", "$rootScope", "rxData"];
            function MediaController($scope, $element, $document, $timeout, $q, dataservice, $state, $location, $rootScope, rxData) {
                var _this = this;
                this.$scope = $scope;
                this.$element = $element;
                this.$document = $document;
                this.$timeout = $timeout;
                this.$q = $q;
                this.dataservice = dataservice;
                this.$state = $state;
                this.$location = $location;
                this.$rootScope = $rootScope;
                this.rxData = rxData;
                this.$onInit = function () {
                    rxData.rootScopeOnready().then(function (data) {
                        _this.pageOBJ = data;
                        console.log(' this.pageOBJ timeline', _this.pageOBJ);
                        $timeout(function () {
                            $scope.$apply();
                        });
                    }, function (err) {
                        _this.pageOBJ = false;
                        console.log('error on home', err);
                    });
                };
            }
            ;
            MediaController.prototype.goTo = function (location) {
                this.$location.path("app/media-gallery/" + location);
            };
            return MediaController;
        }());
        media.MediaController = MediaController;
        var MediaComponent = /** @class */ (function () {
            function MediaComponent() {
                this.restrict = 'E';
                this.controllerAs = 'vm';
                this.templateUrl = 'dist/js/app.media.html';
                this.controller = MediaController;
            }
            return MediaComponent;
        }());
        angular
            .module('app.media', []);
        angular
            .module('app.media').component('media', new MediaComponent());
    })(media = app.media || (app.media = {}));
})(app || (app = {}));
